<template>
  <button
    class="btn btn-primary"
    :class="text ? 'text-button' : ''"
    :title="title"
  >
    <slot></slot>
    <attention-badge-small v-if="attention" class="attention-badge-sm"></attention-badge-small>
  </button>
</template>

<script>
import AttentionBadgeSmall from './AttentionBadgeSmall.vue';

export default {
  props: {
    attention: Boolean,
    title: String,
    text: Boolean,
  },
  components: {
    AttentionBadgeSmall,
  },
};
</script>

<style lang="scss" scoped>
$medium: 1024px;

button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 1em;
  }
}
button:not(:first-child) {
  margin-top: .5em;
}

.text-button {
  width: auto;
  font-weight: 200;
  padding: .5em;
  span {
    margin-left: .3em;
  }
}

.ol-touch {
  button i {
    font-size: 1em !important;
  }
  .text-button {
    font-size: 1.5em !important;
  }
}
</style>
