import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';

Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.mixin({
  methods: {
    trackEvent: (action, name, value) => window._paq.push(['trackEvent', action, name, value]), // eslint-disable-line
  },
});

new Vue({
  render: (h) => h(App),
}).$mount('#map');
