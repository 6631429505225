<!-- eslint-disable -->
<template>
  <div class="ol-download-info-container">
    <div class="ol-download-info-modal">
        <div class="ol-download-info-header">
          <h2 class="text-center">Download</h2>
          <button id="ol-download-info-Close" title="Close Download Information" @click="closeModal"><i class="material-icons btn-icon">close</i></button>
        </div>
        <!-- <div class="col-lg-12 download-service-option">
          <div class="panel panel-default">
            <div class="panel-heading">
              <h3 class="panel-title"><span class="panel-option">Option 1</span> Sentinel-2 cloudless Image Service</h3>
            </div>
            <div class="panel-body">
              <div class="col-lg-12">
                <h4>Just need a wallpaper or a quick snapshot? Use our easy-to-use image service!</h4>
                <button class="btn" id="downloadService" style="margin-bottom: 15px"><i class="material-icons btn-icon downloadIcon">arrow_forward</i> Image Service</button>
                <h4>Attribution &amp; License</h4>
                <ul>
                  <li>Please follow the <a class="a-light" href="#license" target="_blank">attribution requirements</a>.</li>
                  <li>2018, 2019, 2020 or 2021 - <small><a class="a-light" rel="license" target="_blank" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a></small></li>
                  <li>2016 - <small><a class="a-light" rel="license" target="_blank" href="http://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License</a></small></li>
                </ul>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-lg-12 download-full-option">
          <div class="panel panel-default">
            <div class="panel-heading" style="background-color:#004170">
              <h3 class="panel-title"><span class="panel-option">Option 1</span> Sentinel-2 cloudless products on EOxCloudless</h3>
            </div>
            <div class="panel-body">
                <h3>EOxCloudless - Exploitation-ready Satellite Imagery</h3>
                <p>Sentinel-2 cloudless is part of the EOxCloudless Product Family, which offers source data for viewing and analysis.</p>
                <div class="col">
                  <div class="col-md-6" style="padding: 0px">
                      <h4>Viewing Products</h4>
                      <p>Get our prerendered Sentinel-2 cloudless as map cache or create your own layer using our mapping optimized source mosaics for web maps or desktop GIS tools.<br /><br /><a href="https://cloudless.eox.at/#viewing" target="blank" class="a-light">See EOxCloudless Viewing Products</a></p>
                  </div>
                  <div class="col-md-6" style="padding: 0px">
                      <h4>Data Products</h4>
                      <p>Get off-the-shelf multispectral mosaic data from Sentinel-2 or define a custom mosaic tailored for your needs for further analysis and processing.<br /><br /><a href="https://cloudless.eox.at/#data" target="blank" class="a-light">See EOxCloudless Data Products</a></p>
                  </div>
                </div>
                <br />
                <p style="margin-bottom: 15px;">Our products include:</p>
                <ul>
                  <li>Sentinel-2 cloudless single-file products (GeoPackage or MapCache SQLite files)</li>
                  <li>Sentinel-2 cloudless compressed & lossless GeoTIFFS (RGB or RGB/Nir)</li>
                  <li>2016 - 2021 global Sentinel-2 data products</li>
                  <li>Additional sensor data (Sentinel-1 and more)</li>
                  <li>Fast & scalable custom processing options with additional parameters</li>
                </ul>
                <br />
                <p>Visit the EOxCloudless website for examples and more information!</p>
                <a href="//cloudless.eox.at" target="blank" class="btn btn-primary btn-wide" id="fullDataService" style="background-color:#004170"><i class="material-icons btn-icon downloadIcon">arrow_forward</i><span> To the EOxCloudless website</span></a>
              </div>

            </div>
          </div>
          <div class="col-lg-12 download-info-option">
              <div class="panel panel-default">
                <div class="panel-heading">
                  <h3 class="panel-title"><span class="panel-option-light">Option 2</span> Sentinel-2 cloudless WMTS/WMS Access (forever free)</h3>
                </div>
                <div class="panel-body">
                  <h4>Our forever-free access service</h4>
                  <p>Alternatively, feel free to download the data as rendered image mosaics directly from the provided service endpoints (<a class="a-light" href="https://tiles.maps.eox.at/wmts/1.0.0/WMTSCapabilities.xml" target="blank">WMTS</a> or <a class="a-light" href="https://tiles.maps.eox.at/wms?service=wms&request=getcapabilities" target="blank">WMS</a>). You may also use these services directly in your application. Just make sure to follow the license conditions like attribution requirements 2016, 2018 - 2021 and non-commercial usage (2018, 2019, 2020, & 2021). The maximum request size is limited to 4096px, but you may stitch multiple requests to fit your needs.</p>
                  <p>If you are looking for the original GeoTIFF files from 2016 instead, have a look at <a class="a-light" href="https://eox.at/2017/03/sentinel-2-cloudless-original-tiles-available/" target="blank">this blog post</a>. You may download the data as source GeoTIFFs from the AWS S3 bucket eox-s2maps in the eu-central-1 region. Warning: AWS may charge you for download bandwidth.</p>
                  <h4>Attribution &amp; License</h4>
                  <ul>
                    <li>Please follow the <a class="a-light" href="#license" target="_blank">attribution requirements</a>.</li>
                    <li>2018 - 2021 - <small><a class="a-light" rel="license" target="_blank" href="http://creativecommons.org/licenses/by-nc-sa/4.0/">Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License</a></small></li>
                    <li>2016 - <small><a class="a-light" rel="license" target="_blank" href="http://creativecommons.org/licenses/by/4.0/">Creative Commons Attribution 4.0 International License</a></small></li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  mounted() {
    this.trackEvent('download', 'info-modal open');
  },
  methods: {
    closeModal() {
      this.$emit('close');
      this.trackEvent('download', 'info-modal close');
    },
  },
};
</script>

<style lang="scss" scoped>
.ol-download-info-container {
  position: absolute;
  z-index: 2;
  background: rgba(30,30,30,.7);
  z-index: 1002;
  cursor: default;
}

.ol-download-info-modal {
  position: relative;
  width: 92%;
  height: 94vh;
  margin: 3vh auto;
  background: #455A64;
  overflow: auto;
  -webkit-box-shadow: 0px 8px 29px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 8px 29px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 8px 29px 0px rgba(0,0,0,0.75);
}

.ol-download-info-modal {
  background: whitesmoke;
}

.ol-download-info-header, .historyModal-header {
  width: 100%;
  z-index: 25;
  background: whitesmoke;
  position: sticky;
  top: 0;
  padding: 10px 30px;
  background: #455A64;
  color: #fff;
}

.ol-download-info-header h2, .historyModal-header h2 {
  font-size: 20px;
  line-height: 25px;
}

#ol-download-info-Close, #historyModal-Close {
  position: absolute;
  top: 7px;
  right: 0px;
  background: none;
  border: none;
}

@media screen and (min-width: 1024px) {
  .ol-download-info-header h2, .historyModal-header h2 {
    font-size: 35px;
    line-height: 40px;
  }

  #ol-download-info-Close, #historyModal-Close {
    top: 10px;
    right: 10px;
  }
}

.ol-download-info-modal .panel {
  margin-top: 20px;
  border-radius: 0px;
}

.ol-download-info-modal .panel p, #historyModal p {
  margin-bottom: 15px;
  margin-top: 0px;
}

.ol-download-info-modal .panel-heading {
  border-radius: 0px;
}

.ol-download-info-modal .panel-title {
  font-size: 17px;
}

.ol-download-info-modal p, #historyModal p, #historyModal i {
  font-size: 15px;
}

#historyModal p {
  margin-bottom: 15px;
  margin-top: 15px;
}

.panel-option {
  font-weight: bold;
  color: #455A64;
  background: #fff;
  padding: 2px 6px;
  margin-right: 5px;
  border-radius: 4px;
}

.panel-option-light {
  font-weight: bold;
  color: #fff;
  background: #455A64;
  padding: 2px 6px;
  margin-right: 5px;
  border-radius: 4px;
}

.ol-download-info-modal .panel-default > .panel-heading, .ol-download-info-modal .panel-body {
  border: 1px solid #455A64;
}

@media screen and (min-width: 1200px) {
  .ol-download-info-modal .panel-body-img {
    margin-top: 10px;
  }
}

.download-info-option {
  background: whitesmoke;
  height: auto;
}

.download-service-option .panel-heading, .download-full-option .panel-heading {
  background-color: #455A64;
  color: #fff;
}

.download-service-option button, .download-full-option button {
  line-height: 30px;
  margin-top: 15px;
  white-space: normal;
  font-size: 17px;
}

.download-service-option .btn-icon, .download-full-option .btn-icon {
  bottom: -1px;
}

@media screen and (min-width: 1024px) {
  .download-service-option .btn-icon, .download-full-option .btn-icon {
    bottom: -6px;
  }
}
.ol-download-info-modal {
  width: 80%;
  height: 80vh;
  margin: 10vh auto;
}

#modalClose {
  right: 0.7em;
  top: 0.7em;
}

#fullDataService {
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadIcon {
  margin-right: .5em;
}
</style>
