<template>
  <div class="map">
    <Map :interaction="interaction" @closeInteraction="toggleInteraction(false)" />
    <transition name="fade">
      <div v-if="!interaction" class="container">
        <div class="row header-info point int-hide">
          <div class="col-sm-10 col-sm-offset-1 text-center">
            <h1 class="wow fadeIn">Sentinel-2 cloudless by <a href="//eox.at">EOX</a></h1>

            <div class="row">
              <div
                class="col-md-8 col-md-offset-2 col-sm-10 col-sm-offset-1 text-center wow fadeInUp"
                data-wow-delay="0.2s">
                    <button
                      id="introExploreButton"
                      class="btn btn-primary btn-lg btn-intro point exploreButton"
                      title="Interactively explore map"
                      @click="toggleInteraction(!interaction)"
                    >Explore
                      <div
                        class="attention-badge-2"
                      >
                        <strong>NEW</strong><br />
                        <strong>DATA!</strong><br />
                        <strong>2023</strong>
                      </div>
                    </button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-8 col-md-offset-2 col-sm-12 col-sm-offset-10">
                <div class="row">
                  <div class="col-xs-6 text-right wow fadeInUp" data-wow-delay="0.4s">
                    <a
                      href="#be-the-first"
                      class="btn btn-secondary scroll btn-intro point aboutClick"
                    >About<br /><i class="fa fa-angle-down"></i>
                    </a>
                  </div>
                  <div class="col-xs-left text-left wow fadeInUp" data-wow-delay="0.6s">
                    <a href="#eoxcloudless" class="btn btn-secondary scroll btn-intro point">
                      Products<br /><i class="fa fa-angle-down"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </transition>
    <cookie-consent />
  </div>
</template>

<script>
import Map from './components/Map.vue';
import CookieConsent from './components/CookieConsent.vue';

export default {
  name: 'App',
  components: {
    Map,
    CookieConsent,
  },
  data: () => ({
    interaction: false,
  }),
  mounted() {
    const exploreButton = document.getElementById('exploreButton');
    exploreButton.onclick = () => this.toggleInteraction(true);
  },
  methods: {
    toggleInteraction(active) {
      if (active) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
      this.interaction = active;
      document.documentElement.style.overflow = this.interaction ? 'hidden' : 'auto';
    },
  },
};
</script>

<style lang="scss">
:root {
  --v-primary-base: #455A64;
}
</style>
