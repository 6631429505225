<template>
  <!-- eslint-disable -->
  <transition name="slide-fade">
    <div v-if="showCookieBanner" class="cookie-consent-banner">
      <div class="cookie-consent-banner__inner">
        <div class="cookie-consent-banner__copy">
          <div class="cookie-consent-banner__header">ALLOW COOKIES?</div>
          <div class="cookie-consent-banner__description">We use cookies to personalise content and to analyse our traffic. You consent to our cookies by clicking "Accept" or by continuing to use our website. See our <a href="https://eox.at/privacy-notice/" target="_blank" style="color: #455a64;">Privacy Notice</a> for more information.</div>
        </div>

        <div class="cookie-consent-banner__actions">
          <a href="#" class="cookie-consent-banner__cta" @click="onConsentClick(true)">
            Accept
          </a>

          <a href="#" class="cookie-consent-banner__cta cookie-consent-banner__cta--secondary" @click="onConsentClick(false)">
            Decline
          </a>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  data: () => ({
    showCookieBanner: true,
  }),
  created() {
    const givenConsent = this.$cookies.get('mtm_consent');
    this.showCookieBanner = !givenConsent;
  },
  methods: {
    onConsentClick(accept) {
      if (accept) {
        window._paq.push(['rememberConsentGiven']); // eslint-disable-line
      }
      this.showCookieBanner = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(300px);
  opacity: 0;
}

.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;

  background-color: #fff;
}

.cookie-consent-banner__inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0;
  @media screen and (max-width: 960px) {
    max-width: 100%;
    padding: 32px 50px;
  }
  @media screen and (max-width: 500px) {
    max-width: 100%;
    padding: 32px 25px;
  }
}

.cookie-consent-banner__copy {
  margin-bottom: 16px;
}

.cookie-consent-banner__actions {
}

.cookie-consent-banner__header {
  margin-bottom: 8px;

  font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__description {
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  color: #838F93;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  min-width: 164px;
  @media screen and (max-width: 960px) {
    min-width: 145px;
  }
  @media screen and (max-width: 500px) {
    min-width: 120px;
  }
  padding: 11px 13px;

  border-radius: 2px;

  background-color: #455a64;

  color: #FFF;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
  padding: 9px 13px;

  border: 2px solid #3A4649;

  background-color: transparent;

  color: #455a64;
}

.cookie-consent-banner__cta:hover {
  background-color: #455a64;
}

.cookie-consent-banner__cta--secondary:hover {
  border-color: #838F93;

  background-color: transparent;

  color: #455a64;
}

.cookie-consent-banner__cta:last-child {
  margin-left: 16px;
}
</style>
