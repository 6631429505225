<template>
  <div style="position: absolute; bottom: 0; left: 0; width: 100%; z-index: 3">
    <div class="ol-control map-buttons bottom-left">
      <map-button
        title="Start animated tour"
        @click.native="toggleTour()"
        text
        style="z-index:1"
      >
        <i class="material-icons">{{ tourActive ? 'pause' : 'play_arrow' }}</i> <span>Tour</span>
      </map-button>
    </div>
    <div
      v-if="tourActive"
      style="position: absolute; width: 100%; bottom: 0; padding: 0 10vw; z-index:0"
    >
      <vueper-slides
        :autoplay="tourActive"
        :duration="18000"
        class="no-shadow"
        ref="slides"
        :visible-slides="visibleSlides"
        :slide-ratio="1 / visibleSlides"
        :dragging-distance="80"
        @before-slide="beforeSlide">
        <vueper-slide
          v-for="(location, index) in locations"
          :key="index"
          :title="location.title && location.title"
          :content="location.content && location.content"
          @click.native="$refs.slides && $refs.slides.goToSlide(index)"
        />
      </vueper-slides>
    </div>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
import MapButton from './MapButton.vue';

import tourLocations from '../tourLocations.json';

export default {
  components: {
    MapButton,
    VueperSlides,
    VueperSlide,
  },
  methods: {
    toggleTour() {
      this.tourActive = !this.tourActive;
      this.$emit('active', this.tourActive);
      if (this.tourActive) {
        this.$emit('setLocation', this.locations[this.tourIndex]);
      } else {
        this.$emit('stopTour');
      }
      this.trackEvent('map', `tour ${this.tourActive ? 'start' : 'pause'}`);
    },
    beforeSlide({ nextSlide }) {
      this.tourIndex = nextSlide.index;
      this.$emit('setLocation', this.locations[this.tourIndex]);
    },
    next() {
      if (this.tourIndex < this.locations.length - 1) {
        this.tourIndex += 1;
      } else {
        this.tourIndex = 0;
      }
      this.$emit('setLocation', this.locations[this.tourIndex]);
    },
    calcVisibleSlides() {
      let slides = 9;
      if (window.innerWidth < 1000) {
        slides = 6;
      }
      if (window.innerWidth < 800) {
        slides = 5;
      }
      if (window.innerWidth < 600) {
        slides = 4;
      }
      if (window.innerWidth < 500) {
        slides = 3;
      }
      this.visibleSlides = slides;
    },
  },
  data: () => ({
    tourActive: false,
    tourIndex: 0,
    visibleSlides: 9,
    // Tour Locations
    settings: {
      centerMode: true,
      centerPadding: '20px',
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 3,
      speed: 500,
    },
    locations: tourLocations,
  }),
  created() {
    window.addEventListener('resize', this.calcVisibleSlides);
    this.calcVisibleSlides();
  },
  destroyed() {
    window.removeEventListener('resize', this.calcVisibleSlides);
  },
};
</script>

<style lang="scss" scoped>
.vueperslide {
  background: var(--v-primary-base);
  color: white;
  opacity: .8;
  transform: scale(.85);
  cursor: pointer;
  padding: 1em;
}
@media only screen and (min-width: 1900px) {
  ::v-deep .vueperslides__inner {
    font-size: 1.5em;
  }
}
.vueperslide.vueperslide--active {
  transform: scale(1);
}
::v-deep .vueperslide__title {
  font-weight: 800;
}
::v-deep .vueperslides__arrow, ::v-deep .vueperslides__bullets {
  color: white;
}
</style>
